@import './variables.scss';

* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600&display=swap');

html{
  body{
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    font-family: 'Raleway', sans-serif;
    display: flex;
    min-height: 100vh;
    font-size: 16px;
    line-height: 22px;

    #root{
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    button{
      cursor: pointer;
      transition: all ease-in-out .2s;
    }

    button.icon{
      background: none;
      border: none;
      padding: 0;
    }

    a.button{
      display: inline-block;
      background-color: #4aa4f8;
      color: #fff;
      padding: 8px 10px;
      text-decoration: none;
    }

    .container{
      max-width: 850px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: left;

      @include media-screen-sm{
        padding: 20px 40px;
      }
    }

    .nav-header{
      position: fixed;
      left: 0;
      right: 0;
      background: #2b3757;
      margin: 0;
      font-variant: small-caps;
      font-size: 1.4rem;
      padding: 10px 20px;
      height: 43px;

      @include media-screen-sm{
        padding: 10px 40px;
      }

      button{
        color: #fff;
        float: left;
        line-height: 0;

        &:hover, &:focus{
          color: darken(#fff, 10%);
        }

        svg{
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }

    nav{
      background: #2b3757;
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100%;
      right: 100%;
      transition: all ease-in-out .3s;
      z-index: 1;

      &.open{
        left: 0;
        right: 0;
      }

      @include media-screen-sm{
        width: 250px;
        right: auto;

        &.open{
          right: auto;
        }
      }

      h3{
        color: #fff;
        margin: 0;
        font-variant: small-caps;
        font-size: 1.4rem;
        line-height: 1.4rem;
        background-color: #4aa4f8;
        padding: 10px;
        height: 43px;

        button{
          color: #fff;
          float: right;
          line-height: 0;
  
          svg{
            font-size: 1.5em;
          }
        }
      }

      .container{
        padding: 0;
        ul{
          margin: 0;
          padding: 0;
  
          li{
            list-style: none;
  
            a{
              color: #fff;
              text-decoration: none;
              padding: 10px 15px;
              display: block;
              opacity: .8;
              font-size: 1.1rem;
              line-height: 1.2rem;
              transition: all ease-in-out .15s;
  
              &:hover, &:focus{
                opacity: 1;
                background: #1f2840;
              }
            }
  
            &:first-child a{
              padding-top: 20px;
            }
            &:last-child a{
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    header{
      margin-top: 43px;
      .container{
        background: #fff;
        border-bottom: 1px solid #2b3757;
        flex-direction: column;
        align-items: center;
        .logo{
          img{
            max-height: 40px;
            float: left;
            max-width: 40px;
          }
          .name{
            margin-left: 50px;
            h1{
              font-size: 1.5rem;
              line-height: 1.5rem;
              font-weight: 200;
              margin: 0;
              white-space: nowrap;
            }
            h3{
              font-weight: 200;
              font-size: 1rem;
              line-height: 1rem;
              margin: 0;
              opacity: .8;
              white-space: nowrap;
            }
          }
        }
        .buttons{
          margin-top: 20px;
          a{
            background-color: #4aa4f8;
            color: #fff;
            padding: 8px 10px;
            text-decoration: none;
            &.request-demo{
              background-color: rgb(68, 177, 47);
              margin-right: 10px;
            }
          }
        }

        @include media-screen-sm{
          flex-direction: row;

          .buttons{
            margin: 0;
            margin-left: auto;
          }
        }
      }
    }

    @include media-screen-md{
      .nav-header{
        display: none;
      }
      
      nav{
        position: fixed;
        width: auto;
        left: 0;
        right: 0;
        top: auto;
        bottom: auto;

        h3{
          display: none;
        }

        .container{
          justify-content: right;
          flex-direction: row;
          ul{
            display: flex;
            flex-direction: row;
            
            li, li:first-child, li:last-child{
              a{
                padding: 10px 20px;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    footer{
      background: #2b3757;
      color: #fff;

      .container{
        h4{
          display: block;
          margin: 10px 0 5px 0;
          font-size: 1.2rem;
        }
  
        ul{
          display: block;
          list-style: none;
          margin: 0 0 10px 0;
          padding: 0 0 0 10px;
          li{
            margin: 5px 0;
            a{
              color: #fff;
              text-decoration: none;
              &:hover, &:focus{
                color: darken(#fff, 10%);
              }
            }
          }
        }
      }

      h3{
        background: #1F2840;
        padding: 10px;
        text-align: center;
        font-variant: small-caps;
        font-size: 1.3rem;
        line-height: 1rem;
        display: flex;
        flex-direction: row;
        font-weight: 400;
        justify-content: center;
        margin: 0;

        .year{
          font-size: 1.1rem;
          margin-right: 5px;
          margin-top: 1px;
        }

        svg{
          height: 1rem;
          margin-top:2px;
          margin-right: 5px;
        }
      }
    }

    main{
      flex-grow: 1;

      .container{
        background: #fff;
        height: 100%;

        .banner{
          text-align: center;
          img{
            max-width: 150px;
            max-height: 130px;
            margin: 0 auto 20px;
          }
          .button{
            display: inline-block;
            font-variant: small-caps;
            font-weight: 600;
            font-size: 1.7em;
            line-height: 1.1em;
            padding: 10px 30px;
          }

          @include media-screen-md{
            display: flex;
            flex-direction: row;
            align-items: center;

            p{
              padding: 0 10px;
            }
          }
        }

        article{
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          overflow: hidden;

          h1{
            order: 1;
            font-size: 1.5rem;
            margin-top: 0;
            font-weight: 200;
            small{
              display: block;
              font-size: 0.6em;
              font-weight: 200;
              margin-top: 10px;
            }
            sup{
              font-size: 0.6em;
            }

            &.fold{
              display: inline-block;
            }
          }

          p{
            order: 4;
            margin-bottom: 5px;
          }

          a.read-more, a.resource-link{
            order: 5;
            color: #4aa4f8;
            font-variant: small-caps;
            display: block;
            margin-top: 5px;
          }

          .img-container{
            order: 3;
            width: 50%;
            display: block;
            margin: 0 auto;
            text-align: center;

            img{
              max-width: 100%;
              max-height: 180px;
            }
          }

          &:first-of-type{
            margin-top: 20px;
          }

          @include media-screen-sm{
            display: block;

            h1{
              font-size: 1.6rem;
            }

            .img-container{
              width: 50%;
              img{
                max-height: 250px;
              }
            }

            p{
              margin-top: 0;
            }

            &:nth-of-type(even){
              .img-container{
                float: left;
              }
              p{
                padding-left: 55%;
              }
            }

            &:nth-of-type(odd){
              .img-container{
                float: right;
              }
              p{
                padding-right: 55%;
              }
            }

            &.small-image{
              .img-container{
                width: 50%;
                img{
                  max-height: 550px;
                }
              }
  
              &:nth-of-type(even){
                p{
                  padding-left: 55%;
                }
              }
  
              &:nth-of-type(odd){
                p{
                  padding-right: 55%;
                }
              }
            }
          }
        }

        section.software, section.training, section.projects, section.pricing, section.clients, section.home{
          @include media-screen-md{
            article{
              .img-container{
                width: 55%;
                img{
                  max-height: 350px;
                }
              }
  
              &:nth-of-type(even){
                p{
                  padding-left: 58%;
                }
              }
  
              &:nth-of-type(odd){
                p{
                  padding-right: 58%;
                }
              }

              &.small-image{
                .img-container{
                  width: 55%;
                  img{
                    max-height: 550px;
                  }
                }
    
                &:nth-of-type(even){
                  p{
                    padding-left: 58%;
                  }
                }
    
                &:nth-of-type(odd){
                  p{
                    padding-right: 58%;
                  }
                }
              }
            }
          }
        }

        section.pricing{
          h4{
            margin-bottom: 10px;
          }
          ul{
            margin: 0;
            list-style: none;
            padding: 0 0 0 10px;
          }
          a.button{
            margin-top: 10px;
          }
          .pricing-calculator{
            order: 3;
            text-align: center;
            display: inline-block;
            margin: 20px auto 0;
            border: 2px solid #4aa4f8;
            padding: 10px;
            font-size: 14px;
            b{
              margin-bottom: 10px;
            }
            input{
              border: none;
              border-bottom: 1px solid #d3d3d3;
              font-size: 20px;
              line-height: 25px;
              text-align: center;
              padding: 5px 10px;
              outline: none;
              width: 80px;
            }
            .pricing-result{
              margin-top: 10px;
              font-size: 20px;
              span{
                padding: 0 15px;
              }
            }
          }
          .img-container{
            order: 2;
          }
          p{
            margin-top: 0;
          }
          @include media-screen-sm{
            .pricing-calculator{
              max-width: calc(50% + 1px);
              float: right;
              font-size: 16px;
              margin: 0 auto 20px;
            }
            .img-container{
              clear: both;
            }
          }
        }

        section.contact{
          h1{
            font-weight: 200;
          }
          form{
            label{
              display: none;

            }

            input, textarea{
              font-family: 'Raleway', sans-serif;
              border: none;
              border-bottom: 1px solid #d3d3d3;
              padding: 5px 10px;
              font-size: 18px;
              line-height: 30px;
              font-weight: 400;
              margin-bottom: 20px;
              box-sizing: border-box;

              &::placeholder{
                font-weight: 200;
              }

              &:hover, &:focus, &:active{
                outline: none;
                border-bottom: 1px solid #4aa4f8;
              }
            }

            input, textarea{
              width: 100%;
            }

            button{
              display: block;
              background-color: #4aa4f8;
              color: #fff;
              padding: 8px 10px;
              text-decoration: none;
              border: none;
              font-size: 18px;
              line-height: 30px;
            }

            textarea{
              min-height: 170px;
            }

            @include media-screen-sm{
              #firstName, #lastName{
                max-width: 47%;
              }
              #lastName{
                margin-left: 6%;
              }
            }
          }

          .successNotification, .errorNotification{
            position: fixed;
            top: 100%;
            left: 0;
            right: 0;
            text-align: center;
            transition: all ease-in-out .3s;

            .content{
              padding: 20px 40px;
              background: #fff;
              display: inline-block;
              border: 2px solid #2b3757;
            }

            &.show{
              top: auto;
              bottom: 20px;
            }
          }
        }

        section.privacy-policy, section.cookie-policy, section.terms-of-service{
          h1{
            text-align: center;
          }

          a{
            text-decoration: none;
            color: #4aa4f8;
            &:hover{
              color: darken(#4aa4f8, 20%);
            }
          }
        }

        section.cookie-policy{
          #cookie-table{
            margin: 20px auto;
            border-spacing: 0;
            td, th{
              padding: 5px 10px;
              text-align: center;
            }
            tr:nth-child(even){
              background: #f5f5f5;
            }
          }
        }

        section.questel-data{
          box-sizing: border-box;
          img.banner{
            margin: -20px 0 0 -20px;
            max-width: calc(100% + 40px);
            @include media-screen-sm{
              margin: -20px 0 0 -40px;
              max-width: calc(100% + 80px);
            }
          }
          h1{
            font-size: 2rem;
            line-height: 2rem;
            display: inline-block;
            margin: 30px 0 15px;
            small{
              display: block;
              font-size: 0.5em;
              font-weight: 200;
              opacity: .9;
            }
          }
          .grid{
            .block{
              h2{
                font-size: 1.3rem;
              }
              p{
                margin-bottom: 30px;
              }
              p+p{
                margin-top: -10px;
              }
              blockquote{
                padding: 35px 0 0 45px;
                margin: 30px 0 40px;
                font-size: 1.4em;
                position: relative;
                svg{
                  position: absolute;
                  top: 0;
                  left: 0;
                  font-size: 35px;
                }
              }
              ul{
                list-style: none;
                margin: 0;
                padding: 0;
                border: 1px solid #2b3757;
                padding: 10px;
                li{
                  position: relative;
                  padding: 5px 0 5px 20px;
                  svg{
                    position: absolute;
                    left: 0;
                    top: 7px;
                    color: green;
                  }
                }
              }
            }
          }
          @include media-screen-md{
            .grid{
              display: grid;
              grid-template-columns: 1fr 1fr;
              column-gap: 20px;
              columns: 2;
              .block{
                p{
                  margin-bottom: 20px;
                }
                p+p{
                  margin-top: 0px;
                }
                &.double-row{
                  grid-column-start: 2;
                  grid-row: 1/3;
                }
                &.triple-row{
                  grid-row: 3/6;
                }
                blockquote{
                  margin: 15px 0 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}