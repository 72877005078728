@import './variables.scss';

#error-page{
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    .center{
        text-align: center;
        h1{
            font-size: 8rem;
            line-height: 8rem;;
            font-variant: small-caps;
            font-weight: 200;
            margin: 0;
            small{
                font-size: 0.7em;
            }
        }
        h2{
            font-size: 1rem;
            font-weight: 200;
            margin: 0;
        }
        .button{
            margin: 40px 0 0;
        }
        @include media-screen-md{
            h1{
                font-size: 15rem;
                line-height: 11rem;
            }
            h2{
                font-size: 1.3rem;
                opacity: .7;
            }
        }
    }
}